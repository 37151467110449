import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { ButtonActionType, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { FormSubmissionService } from '../../../services/form-submission-service/form-submission-service.service';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { functionCtaType, redirectToPage } from '../cta-button-actions.utils';
import { checkAuthSmsStatusAndRedirect } from '../domestic-payments/check-sms-auth-status-and-redirect';
import { checkFormActionDataKycDetailsAndRedirect } from '../kyc-zone/kyc-zone-actions.utils';
import { submitVerificationProfileChanges } from '../verification-zone/submit-profile-changes-in-verification-flow';

export const kycVerificationActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.REVIEW_INFORMATION_VERIFICATION_PERSONAL,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.REVIEW_INFORMATION_VERIFICATION_BUSINESS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.CHECK_SMS_AUTH_STATUS_AND_REDIRECT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      checkAuthSmsStatusAndRedirect(
        injector.get(Router),
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
      );
    },
  ],
  [
    ButtonActionType.KYC_EMAIL_PHONE_VERIFIED_CHECK,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      checkFormActionDataKycDetailsAndRedirect(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.SUBMIT_PROFILE_CHANGES_IN_VERIFICATION_FLOW,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      submitVerificationProfileChanges(
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(FormSubmissionService),
      );
    },
  ],
  [
    ButtonActionType.VERIFICATION_EDIT_INFORMATION,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
]);
