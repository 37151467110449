export interface IconType {
  name: string;
  url: string;
  set: string;
}

export const iconSetPrimeNg: IconType[] = [
  { name: 'bell', url: 'assets/icon-library/bell-icon.svg', set: 'PrimeNg1' },
  { name: 'book', url: 'assets/icon-library/book-icon.svg', set: 'PrimeNg' },
  { name: 'bookmark-tag', url: 'assets/icon-library/bookmark-tag.svg', set: 'PrimeNg' },
  { name: 'bookmark-fill', url: 'assets/icon-library/bookmark-fill.svg', set: 'PrimeNg' },
  { name: 'arrow-up', url: 'assets/icon-library/arrow-up.svg', set: 'PrimeNg' },
  { name: 'arrow-left', url: 'assets/icon-library/arrow-left.svg', set: 'PrimeNg' },
  { name: 'left-arrow', url: 'assets/images/arrow-left.svg', set: 'PrimeNg' },
  { name: 'arrow-right', url: 'assets/images/arrow-right.svg', set: 'PrimeNg' },
  { name: 'arrow-right-jamboo', url: 'assets/icon-library/arrow-right.svg', set: 'PrimeNg' },
  { name: 'euro-icon', url: 'assets/images/euro-icon.svg', set: 'PrimeNg' },
  { name: 'arrow-down', url: 'assets/icon-library/arrow-down.svg', set: 'PrimeNg' },
  { name: 'trash', url: 'assets/images/trash.svg', set: 'PrimeNg' },
  { name: 'user', url: 'assets/images/user.svg', set: 'PrimeNg' },
  { name: 'copy', url: 'assets/images/copy.svg', set: 'PrimeNg' },
  { name: 'lock', url: 'assets/images/lock.svg', set: 'PrimeNg' },
  { name: 'calendar', url: 'assets/icon-library/calendar-blank.svg', set: 'PrimeNg' },
  { name: 'chart-line', url: 'assets/icon-library/chart-line.svg', set: 'PrimeNg' },
  { name: 'cube', url: 'assets/icon-library/cube.svg', set: 'PrimeNg' },
  { name: 'database', url: 'assets/icon-library/database.svg', set: 'PrimeNg' },
  { name: 'dots-vertical', url: 'assets/icon-library/dots-vertical.svg', set: 'PrimeNg' },
  { name: 'fi-menu', url: 'assets/icon-library/fi-menu.svg', set: 'PrimeNg' },
  { name: 'folder', url: 'assets/icon-library/folder.svg', set: 'PrimeNg' },
  { name: 'home-icon', url: 'assets/icon-library/home-icon.svg', set: 'PrimeNg' },
  { name: 'key-icon', url: 'assets/icon-library/key-icon.svg', set: 'PrimeNg' },
  { name: 'lock-icon', url: 'assets/icon-library/lock-icon.svg', set: 'PrimeNg' },
  { name: 'mail-envelope', url: 'assets/icon-library/mail-envelope.svg', set: 'PrimeNg' },
  { name: 'monitor-screen', url: 'assets/icon-library/monitor-screen.svg', set: 'PrimeNg' },
  { name: 'open-foler', url: 'assets/icon-library/open-foler.svg', set: 'PrimeNg' },
  { name: 'piechart', url: 'assets/icon-library/piechart.svg', set: 'PrimeNg' },
  { name: 'setting-gear', url: 'assets/icon-library/setting-gear.svg', set: 'PrimeNg' },
  { name: 'shield', url: 'assets/icon-library/shield.svg', set: 'PrimeNg' },
  { name: 'sign-in', url: 'assets/icon-library/sign-in.svg', set: 'PrimeNg' },
  { name: 'skip', url: 'assets/icon-library/skip.svg', set: 'PrimeNg' },
  { name: 'sort-alt', url: 'assets/icon-library/sort-alt.svg', set: 'PrimeNg' },
  { name: 'suitcase', url: 'assets/icon-library/suitcase.svg', set: 'PrimeNg' },
  { name: 'tabgrid', url: 'assets/icon-library/tabgrid.svg', set: 'PrimeNg' },
  { name: 'tags-multiple', url: 'assets/icon-library/tags-multiple.svg', set: 'PrimeNg' },
  { name: 'threedot-horizontal', url: 'assets/icon-library/threedot-horizontal.svg', set: 'PrimeNg' },
  { name: 'tick-icon', url: 'assets/icon-library/tick-icon.svg', set: 'PrimeNg' },
  { name: 'copy-landscape', url: 'assets/icon-library/copy-landscape.svg', set: 'PrimeNg' },
  { name: 'credit-card', url: 'assets/icon-library/credit-card.svg', set: 'PrimeNg' },
  { name: 'upload', url: 'assets/icon-library/upload.svg', set: 'PrimeNg' },
  { name: 'history', url: 'assets/icon-library/history.svg', set: 'PrimeNg' },
  { name: 'wallet-card', url: 'assets/icon-library/wallet-card.svg', set: 'PrimeNg' },
  { name: 'user-edit', url: 'assets/icon-library/user-edit.svg', set: 'PrimeNg' },
  { name: 'face-id', url: 'assets/icon-library/face-id-icon.svg', set: 'PrimeNg' },
  { name: 'bell-notification', url: 'assets/icon-library/bell-notification-icon.svg', set: 'PrimeNg' },
  { name: 'search', url: 'assets/icon-library/search.svg', set: 'PrimeNg' },
  { name: 'currency-account', url: 'assets/icon-library/currency-account-icon.svg', set: 'PrimeNg' },
  { name: 'exchange-currency', url: 'assets/icon-library/exchange-currency-icon.svg', set: 'PrimeNg' },
  { name: 'exclamation-circle', url: 'assets/icon-library/exclamation-circle.svg', set: 'PrimeNg' },
  { name: 'international', url: 'assets/icon-library/international-repo.svg', set: 'PrimeNg' },
  { name: 'form-completed-icon', url: 'assets/images/form-completed-icon.svg', set: 'PrimeNg' },
  { name: 'move-item-icon', url: 'assets/images/move-item-icon.svg', set: 'PrimeNg' },
  { name: 'pass-eye', url: 'assets/images/eye.svg', set: 'PrimeNg' },
  { name: 'pass-eye-hide', url: 'assets/images/eye-hide.svg', set: 'PrimeNg' },
  { name: 'times', url: 'assets/icon-library/alert-close.svg', set: 'PrimeNg' },
];

export const iconSetMaterialBold: IconType[] = [
  { name: 'web', url: 'assets/icon-library/web.svg', set: 'Material Bold' },
  { name: 'verification', url: 'assets/icon-library/verification.svg', set: 'Material Bold' },
  { name: 'user-profile', url: 'assets/icon-library/user-profile.svg', set: 'Material Bold' },
  { name: 'user-authentication', url: 'assets/icon-library/user-authentication.svg', set: 'Material Bold' },
  { name: 'trending', url: 'assets/icon-library/trending.svg', set: 'Material Bold' },
  { name: 'timer-alert', url: 'assets/icon-library/timer-alert.svg', set: 'Material Bold' },
  { name: 'support-chat', url: 'assets/icon-library/support-chat.svg', set: 'Material Bold' },
  { name: 'support-agent', url: 'assets/icon-library/support-agent.svg', set: 'Material Bold' },
  {
    name: 'success-check-circle-outline',
    url: 'assets/icon-library/success-check-circle-outline.svg',
    set: 'Material Bold',
  },
  { name: 'single-beneficiary', url: 'assets/icon-library/single-beneficiary.svg', set: 'Material Bold' },
  { name: 'settings', url: 'assets/icon-library/settings.svg', set: 'Material Bold' },
  { name: 'report', url: 'assets/icon-library/report.svg', set: 'Material Bold' },
  { name: 'privacy-policy', url: 'assets/icon-library/privacy-policy.svg', set: 'Material Bold' },
  { name: 'plus-circle-outline', url: 'assets/icon-library/plus-circle-outline.svg', set: 'Material Bold' },
  { name: 'payments', url: 'assets/icon-library/payments.svg', set: 'Material Bold' },
  { name: 'pay', url: 'assets/icon-library/pay.svg', set: 'Material Bold' },
  { name: 'notifications', url: 'assets/icon-library/notifications.svg', set: 'Material Bold' },
  {
    name: 'notification-settings',
    url: 'assets/icon-library/notification-settings.svg',
    set: 'Material Bold',
  },
  { name: 'more', url: 'assets/icon-library/more.svg', set: 'Material Bold' },
  { name: 'magnifying-glass', url: 'assets/icon-library/magnifying-glass.svg', set: 'Material Bold' },
  { name: 'lock-password', url: 'assets/icon-library/lock-password.svg', set: 'Material Bold' },
  {
    name: 'international-payment',
    url: 'assets/icon-library/international-payment.svg',
    set: 'Material Bold',
  },
  { name: 'help-circle-outline', url: 'assets/icon-library/help-circle-outline.svg', set: 'Material Bold' },
  { name: 'faq', url: 'assets/icon-library/faq.svg', set: 'Material Bold' },
  { name: 'eye-off', url: 'assets/icon-library/eye-off.svg', set: 'Material Bold' },
  { name: 'exchange', url: 'assets/icon-library/exchange.svg', set: 'Material Bold' },
  { name: 'error', url: 'assets/icon-library/error.svg', set: 'Material Bold' },
  { name: 'edit', url: 'assets/icon-library/edit.svg', set: 'Material Bold' },
  { name: 'edit-user-account', url: 'assets/icon-library/edit-user-account.svg', set: 'Material Bold' },
  { name: 'edit-beneficiary', url: 'assets/icon-library/edit-beneficiary.svg', set: 'Material Bold' },
  { name: 'close-user-account', url: 'assets/icon-library/close-user-account.svg', set: 'Material Bold' },
  { name: 'chevron-up', url: 'assets/icon-library/chevron-up.svg', set: 'Material Bold' },
  { name: 'chevron-right', url: 'assets/icon-library/chevron-right.svg', set: 'Material Bold' },
  { name: 'chevron-left', url: 'assets/icon-library/chevron-left.svg', set: 'Material Bold' },
  { name: 'cash', url: 'assets/icon-library/cash.svg', set: 'Material Bold' },
  { name: 'business', url: 'assets/icon-library/business.svg', set: 'Material Bold' },
  { name: 'bin-delete', url: 'assets/icon-library/bin-delete.svg', set: 'Material Bold' },
  { name: 'beneficiary-list', url: 'assets/icon-library/beneficiary-list.svg', set: 'Material Bold' },
  { name: 'arrow-top-right', url: 'assets/icon-library/arrow-top-right.svg', set: 'Material Bold' },
  { name: 'arrow-bottom-left', url: 'assets/icon-library/arrow-bottom-left.svg', set: 'Material Bold' },
  { name: 'add-beneficiary', url: 'assets/icon-library/add-beneficiary.svg', set: 'Material Bold' },
  { name: 'accounts-list', url: 'assets/icon-library/accounts-list.svg', set: 'Material Bold' },
];

export const iconSetRevenir: IconType[] = [
  { name: 'trip-transaction-icon', url: 'assets/images/trip-transaction-icon.svg', set: 'Revenir' },
  { name: 'trip-transaction-warning', url: 'assets/images/trip-transaction-warning.svg', set: 'Revenir' },
  { name: 'trip-transaction-error', url: 'assets/images/trip-transaction-error.svg', set: 'Revenir' },
  { name: 'trip-transaction-debit', url: 'assets/images/trip-transaction-debit.svg', set: 'Revenir' },
  { name: 'camera', url: 'assets/icon-library/camera.svg', set: 'Revenir' },
  { name: 'gallery-image', url: 'assets/icon-library/gallery-image.svg', set: 'Revenir' },
  { name: 'file', url: 'assets/icon-library/file.svg', set: 'Revenir' },
  { name: 'archive', url: 'assets/icon-library/revenir/rev_archive.svg', set: 'Revenir' },
  { name: 'map-icon', url: 'assets/icon-library/revenir/map-icon.svg', set: 'Revenir' },
  { name: 'burger-icon', url: 'assets/icon-library/revenir/burger-icon.svg', set: 'Revenir' },
  { name: 'barcode-icon', url: 'assets/icon-library/barcode.svg', set: 'Revenir' },
  { name: 'revenir-loading-icon', url: 'assets/icon-library/revenir/loading-icon.svg', set: 'Revenir' },
  { name: 'alert-octagon', url: 'assets/icon-library/revenir/alert-octagon.svg', set: 'Revenir' },
];

export const iconSetMaterial: IconType[] = [
  { name: 'source-notes', url: 'assets/icon-library/material/source-notes.svg', set: 'Material' },
  { name: 'ac-unit', url: 'assets/icon-library/material/ac-unit.svg', set: 'Material' },
  { name: 'add-card', url: 'assets/icon-library/material/add-card.svg', set: 'Material' },
  { name: 'edit-card', url: 'assets/icon-library/material/edit-card.svg', set: 'Material' },
  { name: 'credit-card-off', url: 'assets/icon-library/material/credit-card-off.svg', set: 'Material' },
  { name: 'dialpad', url: 'assets/icon-library/material/dialpad.svg', set: 'Material' },
  { name: 'problem', url: 'assets/icon-library/material/problem.svg', set: 'Material' },
  { name: 'chevron-forward', url: 'assets/icon-library/material/chevron-forward.svg', set: 'Material' },
  { name: 'compare-arrows', url: 'assets/icon-library/material/compare-arrows.svg', set: 'Material' },
  { name: 'mat_add', url: 'assets/icon-library/material/mat_add.svg', set: 'Material' },
  { name: 'brightness-alert', url: 'assets/icon-library/material/brightness-alert.svg', set: 'Material' },
  { name: 'mat_history', url: 'assets/icon-library/material/history.svg', set: 'Material' },
  { name: 'mat_copy', url: 'assets/icon-library/material/mat_copy.svg', set: 'Material' },
  { name: 'mat_share', url: 'assets/icon-library/material/mat_share.svg', set: 'Material' },
  { name: 'mat_phonelink_ring', url: 'assets/icon-library/material/mat_phonelink_ring.svg', set: 'Material' },
  { name: 'mat_encrypted', url: 'assets/icon-library/material/mat_encrypted.svg', set: 'Material' },
  { name: 'mat_done_all', url: 'assets/icon-library/material/mat_done_all.svg', set: 'Material' },
  { name: 'mat_visibility_off', url: 'assets/icon-library/material/mat_visibility_off.svg', set: 'Material' },
  { name: 'mat_warning_circle', url: 'assets/icon-library/material/mat_warning_circle.svg', set: 'Material' },
  { name: 'mat_error', url: 'assets/icon-library/material/mat_error.svg', set: 'Material' },
  {
    name: 'mat_success_check_circle_outline',
    url: 'assets/icon-library/material/mat_success_check_circle_outline.svg',
    set: 'Material',
  },
  { name: 'gallery-thumbnail', url: 'assets/icon-library/material/gallery-thumbnail.svg', set: 'Material' },
  { name: 'bar-chart', url: 'assets/icon-library/material/bar-chart.svg', set: 'Material' },
];

export const iconSetMisc: IconType[] = [
  { name: 'chart-bar', url: 'assets/images/chart-bar.svg', set: 'NgPrime' },
  { name: 'home', url: 'assets/images/home.svg', set: 'NgPrime' },
  { name: 'check-circle-common', url: 'assets/images/check-circle-common.svg', set: 'NgPrime' },
  { name: 'cart', url: 'assets/images/shopping-cart.svg', set: 'NgPrime' },
  { name: 'sign-out-common', url: 'assets/images/sign-out-common.svg', set: 'NgPrime' },
  { name: 'timeline-line', url: 'assets/images/timeline-line.svg', set: 'NgPrime' },
  { name: 'timeline-circle', url: 'assets/images/timeline-circle.svg', set: 'NgPrime' },
  { name: 'inbox', url: 'assets/images/inbox.svg', set: 'NgPrime' },
  { name: 'phone', url: 'assets/images/phone.svg', set: 'NgPrime' },
  { name: 'clock', url: 'assets/images/clock.svg', set: 'NgPrime' },
  { name: 'mastercard-logo', url: 'assets/images/master-logo.svg', set: 'NgPrime' },
  { name: 'times-circle', url: 'assets/images/times-circle.svg', set: 'NgPrime' },
  { name: 'close', url: 'assets/images/close.svg', set: 'NgPrime' },
  { name: 'transaction-pro', url: 'assets/images/transaction-pro.svg', set: 'NgPrime' },
  { name: 'transfer-confirm', url: 'assets/images/transfer-confirm.svg', set: 'NgPrime' },
  { name: 'transfer-failed', url: 'assets/images/transfer-failed.svg', set: 'NgPrime' },
  { name: 'transfer-loader', url: 'assets/images/transfer-loader.svg', set: 'NgPrime' },
  { name: 'user-library', url: 'assets/images/user.svg', set: 'NgPrime' },
  { name: 'more-menu', url: 'assets/images/more-menus.svg', set: 'NgPrime' },
  { name: 'sitemap', url: 'assets/images/sitemap.svg', set: 'NgPrime' },
  { name: 'users', url: 'assets/images/users.svg', set: 'NgPrime' },
  { name: 'user-plus', url: 'assets/images/user-plus.svg', set: 'NgPrime' },
  { name: 'verification-background', url: 'assets/images/verification-background.svg', set: 'NgPrime' },
  { name: 'visa-logo', url: 'assets/images/visa-logo.svg', set: 'NgPrime' },
  { name: 'eye', url: 'assets/images/eye.svg', set: 'NgPrime' },
  { name: 'dot', url: 'assets/images/indicator.svg', set: 'NgPrime' },
  { name: 'popup-dot', url: 'assets/images/popup-pagination.svg', set: 'NgPrime' },
  { name: 'popup-dot-active', url: 'assets/images/popup-pagination-active.svg', set: 'NgPrime' },
  { name: 'remove-custom-widget', url: 'assets/images/remove-icon.svg', set: 'NgPrime' },
  { name: 'add-question', url: 'assets/images/plus.svg', set: 'NgPrime' },
  { name: 'dot-active', url: 'assets/images/indicator-active.svg', set: 'NgPrime' },
  { name: 'back-arrow', url: 'assets/images/arrow-back.svg', set: 'NgPrime' },
  { name: 'show-role', url: 'assets/images/show-role-icon.svg', set: 'NgPrime' },
  { name: 'eye-slash', url: 'assets/images/eye-slash.svg', set: 'NgPrime' },
  { name: 'replay', url: 'assets/images/replay.svg', set: 'NgPrime' },
  { name: 'card-outline', url: 'assets/images/widget-icons/card-outline.svg', set: 'widget-icons' },
  { name: 'star', url: 'assets/images/widget-icons/star.svg', set: 'widget-icons' },
  { name: 'freeze-card', url: 'assets/images/widget-icons/freeze-card.svg', set: 'widget-icons' },
  { name: 'replace-card', url: 'assets/images/widget-icons/replace-card.svg', set: 'widget-icons' },
  { name: 'change-pin', url: 'assets/images/widget-icons/change-pin.svg', set: 'widget-icons' },
  { name: 'lost-card', url: 'assets/images/widget-icons/lost-card.svg', set: 'widget-icons' },
  { name: 'move', url: 'assets/images/move.svg', set: 'NgPrime' },
  { name: 'eye-white', url: 'assets/images/eye-white.svg', set: 'NgPrime' },
  { name: 'eye-slash-white', url: 'assets/images/eye-slash-white.svg', set: 'NgPrime' },
  { name: 'refresh', url: 'assets/images/u_sync.svg', set: 'NgPrime' },
  { name: 'sign-out', url: 'assets/images/sign-out.svg', set: 'NgPrime' },
  { name: 'info', url: 'assets/images/info.svg', set: 'NgPrime' },
  { name: 'info-circle', url: 'assets/images/info-circle.svg', set: 'NgPrime' },
  { name: 'bold-text', url: 'assets/images/bold-text.svg', set: 'NgPrime' },
  { name: 'underline-text', url: 'assets/images/underline-text.svg', set: 'NgPrime' },
  { name: 'three-dots', url: 'assets/images/three-dot.svg', set: 'NgPrime' },
  { name: 'search-icon', url: 'assets/images/search.svg', set: 'NgPrime' },
  { name: 'arrow-up-right', url: 'assets/images/arrow-up-right.svg', set: 'NgPrime' },
  { name: 'italic-text', url: 'assets/images/italic-text.svg', set: 'NgPrime' },
  { name: 'user-icon', url: 'assets/images/user-icon-black.svg', set: 'NgPrime' },
  { name: 'close-icon', url: 'assets/images/error-close-icon.svg', set: 'Misc' },
  { name: 'alert-error-icon', url: 'assets/images/alert-error-icon.svg', set: 'Misc' },
  { name: 'succces-icon', url: 'assets/images/succces-icon.svg', set: 'Misc' },
  { name: 'active-card', url: 'assets/images/exclamation-circle-white.svg', set: 'Misc' },
  { name: 'mandatory-field-icon', url: 'assets/images/mandatory-field-icon.svg', set: 'Misc' },
  { name: 'more-img-option', url: 'assets/images/more-img-option.svg', set: 'Misc' },
  { name: 'error-tooltip-icon', url: 'assets/images/error-tooltip-icon.svg', set: 'Misc' },
  { name: 'arrow-circle-right', url: 'assets/images/arrow-circle-right.svg', set: 'Arrows' },
  { name: 'double-arrow-right', url: 'assets/images/double-arrow-right.svg', set: 'Arrows' },
  { name: 'forward-play', url: 'assets/images/forward-play.svg', set: 'Arrows' },
  { name: 'arrow-circle-left', url: 'assets/images/arrow-circle-left.svg', set: 'Arrows' },
  { name: 'double-arrow-left', url: 'assets/images/double-arrow-left.svg', set: 'Arrows' },
  { name: 'left-play', url: 'assets/images/left-play.svg', set: 'Arrows' },
  { name: 'users-alt', url: 'assets/icon-library/users-alt.svg', set: 'NgPrime' },
  { name: 'pdf-file', url: 'assets/icon-library/pdf-file.svg', set: 'Misc' },
  { name: 'pencil', url: 'assets/images/pencil.svg', set: 'Misc' },
  { name: 'download', url: 'assets/images/download.svg', set: 'Misc' },
  { name: 'facility', url: 'assets/images/facility.svg', set: 'Misc' },
  { name: 'mail-unread', url: 'assets/images/mailUnread.svg', set: 'Misc' },
  { name: 'event-icon', url: 'assets/images/event-icon.svg', set: 'Misc' },
  { name: 'playlist-icon', url: 'assets/images/playlist-icon.svg', set: 'Misc' },
  { name: 'briefcase-icon', url: 'assets/images/briefcase-icon.svg', set: 'Misc' },
  { name: 'question-circle', url: 'assets/icon-library/question-circle.svg', set: 'Misc' },
  { name: 'invoice-icon', url: 'assets/icon-library/invoice-icon.svg', set: 'Misc' },
  { name: 'user-plus-jamboo', url: 'assets/icon-library/users-plus.svg', set: 'NewIcons' },
  { name: 'globe', url: 'assets/icon-library/globe-06.svg', set: 'NewIcons' },
  { name: 'credit-card-2', url: 'assets/icon-library/credit-card-02.svg', set: 'NewIcons' },
  { name: 'bank', url: 'assets/icon-library/bank.svg', set: 'NewIcons' },
  { name: 'chevron-down', url: 'assets/images/chevron-down-white.svg', set: 'NewIcons' },
  { name: 'box', url: 'assets/icon-library/box.svg', set: 'NewIcons' },
  { name: 'user-icon-beneficiary', url: 'assets/icon-library/users-icon-beneficiary.svg', set: 'NewIcons' },
  { name: 'plus-circle', url: 'assets/icon-library/plus-circle.svg', set: 'NewIcons' },
  { name: 'settings-icon', url: 'assets/icon-library/settings-icon.svg', set: 'NewIcons' },
  { name: 'menu-icon', url: 'assets/icon-library/menu-icon.svg', set: 'NewIcons' },
  { name: 'credit-card-refresh', url: 'assets/icon-library/credit-card-refresh.svg', set: 'NewIcons' },
  { name: 'home-library-icon', url: 'assets/icon-library/home-library-icon.svg', set: 'NewIcons' },
  { name: 'qr-code', url: 'assets/icon-library/qr-code.svg', set: 'NewIcons' },
  { name: 'globe-icon', url: 'assets/icon-library/globe-icon.svg', set: 'NewIcons' },
  { name: 'frame-arrow-down', url: 'assets/icon-library/frame-down.svg', set: 'NewIcons' },
  { name: 'frame-arrow-up', url: 'assets/icon-library/frame-up.svg', set: 'NewIcons' },
  { name: 'frame-arrow-right', url: 'assets/icon-library/frame-right.svg', set: 'NewIcons' },
  { name: 'frame-arrow-left', url: 'assets/icon-library/frame-left.svg', set: 'NewIcons' },
  { name: 'code', url: 'assets/images/code.svg', set: 'NewIcons' },
  { name: 'code-editor', url: 'assets/images/code-editor.svg', set: 'NewIcons' },
  { name: 'wallet', url: 'assets/icon-library/wallet-02.svg', set: 'NewIcons' },
  { name: 'copy-icon', url: 'assets/icon-library/copy-06.svg', set: 'NewIcons' },
  { name: 'tick-success', url: 'assets/images/tick-success.svg', set: 'NewIcons' },
  { name: 'payment-decline', url: 'assets/images/payment-decline.svg', set: 'NewIcons' },
  { name: 'plus', url: 'assets/images/plus.svg', set: 'NewIcons' },
  { name: 'car-icon', url: 'assets/icon-library/car-icon.svg', set: 'NewIcons' },
  { name: 'change-password', url: 'assets/icon-library/change-password.svg', set: 'NewIcons' },
  { name: 'add-card-icon', url: 'assets/icon-library/add-card-icon.svg', set: 'NewIcons3' },
  { name: 'dots-pattern', url: 'assets/images/dots-pattern.svg', set: 'Misc' },
  { name: 'user', url: 'assets/icon-library/user.svg', set: 'Misc' },
  { name: 'arrow-narrow-icon', url: 'assets/icon-library/arrow-narrow-right.svg', set: 'Misc' },
  { name: 'code-outline', url: 'assets/icon-library/code.svg', set: 'Misc' },
  { name: 'border-color', url: 'assets/icon-library/border_color.svg', set: 'Misc' },
  { name: 'nights-stay', url: 'assets/icon-library/nights_stay.svg', set: 'Misc' },
  { name: 'navigation-configuration', url: 'assets/images/navigation-configuration.svg', set: 'Misc' },
  { name: 'up-arrow', url: 'assets/images/arrow-up.svg', set: 'NgPrime' },
  { name: 'right-arrow', url: 'assets/images/right-arrow.svg', set: 'PrimeNg' },
  { name: 'trash-white', url: 'assets/images/trash-white.svg', set: 'NewIcons' },
];

export const iconSetPayabl: IconType[] = [
  { name: 'bar-chart-p', url: 'assets/icon-library/payabl/bar-chart.svg', set: 'Payabl' },
  { name: 'bell-p', url: 'assets/icon-library/payabl/bell.svg', set: 'Payabl' },
  { name: 'calendar-p', url: 'assets/icon-library/payabl/calendar.svg', set: 'Payabl' },
  { name: 'chat-circle-p', url: 'assets/icon-library/payabl/chat-circle.svg', set: 'Payabl' },
  { name: 'credit-card-p', url: 'assets/icon-library/payabl/credit-card.svg', set: 'Payabl' },
  { name: 'eye-p', url: 'assets/icon-library/payabl/eye.svg', set: 'Payabl' },
  { name: 'file-text-p', url: 'assets/icon-library/payabl/file-text.svg', set: 'Payabl' },
  { name: 'freeze-p', url: 'assets/icon-library/payabl/freeze.svg', set: 'Payabl' },
  { name: 'home-p', url: 'assets/icon-library/payabl/home.svg', set: 'Payabl' },
  { name: 'info-p', url: 'assets/icon-library/payabl/info.svg', set: 'Payabl' },
  { name: 'mail-p', url: 'assets/icon-library/payabl/mail.svg', set: 'Payabl' },
  {
    name: 'money-simple-from-bracket-p',
    url: 'assets/icon-library/payabl/money-simple-from-bracket.svg',
    set: 'Payabl',
  },
  { name: 'person-p', url: 'assets/icon-library/payabl/person.svg', set: 'Payabl' },
  { name: 'phone-p', url: 'assets/icon-library/payabl/phone.svg', set: 'Payabl' },
  { name: 'property-p', url: 'assets/icon-library/payabl/property.svg', set: 'Payabl' },
  { name: 'refund-p', url: 'assets/icon-library/payabl/refund.svg', set: 'Payabl' },
  { name: 'settings-p', url: 'assets/icon-library/payabl/settings.svg', set: 'Payabl' },
  { name: 'shield-p', url: 'assets/icon-library/payabl/shield.svg', set: 'Payabl' },
  { name: 'shopping-bag-p', url: 'assets/icon-library/payabl/shopping-bag.svg', set: 'Payabl' },
  { name: 'smile-p', url: 'assets/icon-library/payabl/smile.svg', set: 'Payabl' },
  { name: 'top-up-p', url: 'assets/icon-library/payabl/top-up.svg', set: 'Payabl' },
  { name: 'trash-p', url: 'assets/icon-library/payabl/trash.svg', set: 'Payabl' },
  { name: 'chevron-p', url: 'assets/icon-library/payabl/chevron-p.svg', set: 'Payabl' },
  { name: 'alert-success', url: 'assets/icon-library/payabl/alert-success.svg', set: 'Payabl' },
  { name: 'alert-warning', url: 'assets/icon-library/payabl/alert-warning.svg', set: 'Payabl' },
  { name: 'chevron-left', url: 'assets/icon-library/payabl/chevron-left.svg', set: 'Payabl' },
  { name: 'external-link', url: 'assets/icon-library/payabl/external-link.svg', set: 'Payabl' },
  { name: 'briefcase', url: 'assets/icon-library/payabl/briefcase.svg', set: 'Payabl' },
];

export const iconSetGoogle: IconType[] = [
  { name: 'g-pay', url: 'assets/icon-library/google/Google_Pay_Logo.svg', set: 'Google' },
];

export const allIcons: IconType[] = [
  ...iconSetPrimeNg,
  ...iconSetMaterialBold,
  ...iconSetRevenir,
  ...iconSetMaterial,
  ...iconSetMisc,
  ...iconSetPayabl,
  ...iconSetGoogle,
];
