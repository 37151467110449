import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '@app/finxone-web-frontend/app/lib/services/config-service/config-service.service';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import { CredentialType } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { LocaleVariableState } from '../../../..//lib/state/locale-variable.state';
import { KeycloakCustomService } from '../../../../lib/services/keycloak-wrapper-service/keycloak-custom.service';
import { ConfirmationRequest } from '../../../../lib/services/keycloak-wrapper-service/keycloak-custom.type';

type ConfirmationQueryParams = {
  claimType: string;
  confirmationType: CredentialType;
  returnUrl: string;
};

@Component({
  selector: 'finxone-web-frontend-app-pin-confirmation',
  templateUrl: './app-pin-confirmation.component.html',
  styleUrl: './app-pin-confirmation.component.scss',
})
export class AppPinConfirmationComponent implements OnInit {
  @AutoDestroy private destroy$: Subject<void> = new Subject<void>();
  public localeContent$: Observable<any>;
  public localeContent: any;

  private claim: string | undefined = '';
  private confirmation: string | undefined = '';
  private returnUrl: string | undefined = '';
  private flutterWebView = (window as any).flutter_inappwebview;

  public pin = '';
  public error = '';
  public isMobile = false;
  public companyLogo?: string;
  public readonly MAX_LENGTH: number = 6;
  public topPadding = '0px';
  public bottomPadding = '0px';

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private keycloakService: KeycloakCustomService,
    private matIconRegistry: MatIconRegistry,
    private configService: ConfigService,
  ) {
    this.localeContent$ = this.store.select(LocaleVariableState.getLocaleContent);
    this.matIconRegistry
      .getNamedSvgIcon('customer-logo')
      .pipe(take(1))
      .subscribe((logo) => {
        this.companyLogo = this.resizeLogo(logo.outerHTML);
      });
    // This is the in-app padding needed for the safe area on mobile devices
    this.configService
      .getSafePadding()
      .pipe(take(1))
      .subscribe((padding) => {
        this.topPadding = `${padding.topPadding}px`;
        this.bottomPadding = `${padding.bottomPadding}px`;
      });
  }

  ngOnInit(): void {
    document.getElementById('loader')?.remove();
    this.isMobile = window.innerWidth <= 768;

    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: Partial<ConfirmationQueryParams>) => {
        this.claim = params['claimType'];
        this.confirmation = params['confirmationType'];
        this.returnUrl = params['returnUrl'] || '/';

        if (!this.claim || !this.confirmation) {
          console.error('Missing required confirmation parameters');
          this.router.navigate(['/']).then();
        }
      });

    this.localeContent$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (content) => {
        this.localeContent = content; // Might need this to pull out error messages
      },
      error: (error) => {
        console.error('Error loading content:', error);
      },
    });
  }

  public checkPinComplete(): void {
    if (this.pin.length === this.MAX_LENGTH && /^\d+$/.test(this.pin)) {
      setTimeout(() => this.onSubmit(), 200);
    }
  }

  public async onBiometricChallenge(challenge: string): Promise<void> {
    this.confirmation = CredentialType.BIOMETRIC;
    try {
      if (this.flutterWebView) {
        const { signature } = await this.flutterWebView.callHandler('signChallengeWithBiometric', [
          challenge,
        ]);
        const payload: ConfirmationRequest = this.validateAndCreatePayload(signature);

        this.handleConfirmation(payload);
      }
    } catch (error) {
      console.error('Biometric authentication failed:', error);
      this.error = 'Biometric authentication failed. Please try again or use PIN.';
    }
  }

  private async onSubmit(): Promise<void> {
    this.confirmation = CredentialType.PIN; // in case someone tries biometrics, then PIN again
    try {
      const payload: ConfirmationRequest = this.validateAndCreatePayload(this.pin);
      this.handleConfirmation(payload);
    } catch (error) {
      console.error(error);
      this.error = 'Invalid passcode, please try again'; // Placeholder - var could be populated via locale content
      this.pin = '';
    }
  }

  private handleConfirmation(payload: ConfirmationRequest) {
    this.keycloakService
      .sendConfirmation(payload)
      .then(({ access_token }) => {
        const hasQueryParams = this.returnUrl?.includes('?');
        const queryParams: URLSearchParams = new URLSearchParams();
        queryParams.set('confirmation', access_token);
        // Construct the final URL
        const fullUrl = `${this.returnUrl}${hasQueryParams ? '&' : '?'}${queryParams?.toString()}`;
        this.router.navigateByUrl(fullUrl, { replaceUrl: true });
      })
      .catch((error) => {
        console.error('Error during confirmation:', error);
      });
  }

  private validateAndCreatePayload(value: string): ConfirmationRequest {
    if (!this.claim?.length || !this.confirmation?.length) {
      throw new Error('Missing required fields for confirmation payload');
    }
    return {
      claim: this.claim,
      factors: [
        {
          type: this.confirmation,
          value: value,
        },
      ],
    };
  }

  onAltFlowClick(event: Event): void {
    event.preventDefault();
  }

  onBackClick(event: Event): void {
    event.preventDefault();
    this.location.back();
  }

  private resizeLogo(svg?: string): string | undefined {
    let processedLogo = svg ? svg.replace(/(height=["'])[^"']*["']/, `$185px"`) : undefined;
    // If no height exists, add it
    if (processedLogo && !processedLogo.includes('height=')) {
      processedLogo = processedLogo.replace('<svg', '<svg height="85px" ');
    }

    processedLogo = processedLogo ? processedLogo.replace(/(width=["'])[^"']*["']/, `$1100%"`) : undefined;
    // If no height exists, add it
    if (processedLogo && !processedLogo.includes('width=')) {
      processedLogo = processedLogo.replace('<svg', '<svg width="100%" ');
    }
    return processedLogo;
  }
}
