import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  AddEmployeeRequestType,
  BaseWidgetProperties,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { map } from 'rxjs';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import { GetUsersList } from '../../../actions/users-list.action';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { ProfileState } from '../../../state/user-profile.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Fetches the data from FormAction state and call the Add Employee method
 */
export function addEmployeeMethod(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  metadataService: MetadataService,
  attri: UiZoneWidgetAttributeConfig,
) {
  const addEmployeeStateValue = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_EMPLOYEE),
  );

  const formActionStateValue = store.selectSnapshot(FormActionState.getFormActionState);

  const profileStateValue = store.selectSnapshot(ProfileState.getProfile);

  const addEmployeeRequestFormData = {
    ...addEmployeeStateValue?.formData,
    ...formActionStateValue.response?.formData,
    activeOrganisationId: profileStateValue.activeOrganisationId,
  };

  if (!addEmployeeRequestFormData['email']) {
    alertHandlerService.showAlertFn('error', 'Email is a mandatory field.');
  } else if (!addEmployeeRequestFormData['first_name'] || !addEmployeeRequestFormData['last_name']) {
    alertHandlerService.showAlertFn('error', 'First name and last name are mandatory fields.');
  } else if (!addEmployeeRequestFormData['phone'] && !addEmployeeRequestFormData['country_code']) {
    alertHandlerService.showAlertFn('error', 'Phone and Country code are mandatory fields.');
  } else if (!addEmployeeRequestFormData['userName']) {
    alertHandlerService.showAlertFn('error', 'User name is a mandatory field.');
  } else if (!addEmployeeRequestFormData['password']) {
    alertHandlerService.showAlertFn('error', 'password is a mandatory field.');
  } else if (addEmployeeRequestFormData['password'] !== addEmployeeRequestFormData['confirm_password']) {
    alertHandlerService.showAlertFn('error', `Confirm password doesn't match with password.`);
  } else {
    const addEmployeeRequest = {
      firstName: addEmployeeRequestFormData['first_name'],
      middleName: addEmployeeRequestFormData['middle_name'],
      lastName: addEmployeeRequestFormData['last_name'],
      email: addEmployeeRequestFormData['email'],
      organisationId: addEmployeeRequestFormData['activeOrganisationId'], // active organisation
      roleId: 'employee', // static as of now, it has to be there in app grid
      emailVerified: false, // static as of now
      phoneVerified: false, // static as of now
      additionalRoles: [],
      password: addEmployeeRequestFormData['password'],
      phoneNumber: `${addEmployeeRequestFormData['country_code']}${addEmployeeRequestFormData['phone']}`,
      userName: addEmployeeRequestFormData['userName'],
      profilePicture:
        addEmployeeRequestFormData?.[attri?.['profilePictureConfiguration']?.pictureKey]?.[0]?.id,
    } as AddEmployeeRequestType;

    metadataService
      .addEmployee(addEmployeeRequest)
      .pipe(
        map((response) => {
          if (response['userId']) {
            redirectToPage(router, widgetProperties);
            store.dispatch(new GetUsersList(addEmployeeRequestFormData['activeOrganisationId'], 1, 10));
          } else {
            redirectToPage(router, {
              textContent: '',
              zoneToNavigate: APP_ZONES.USER_MANAGEMENT,
              urlToNavigate: 'assign-failed',
            } as BaseWidgetProperties);
          }
          store.dispatch(new ResetFormDataAction());
        }),
      )
      .subscribe();
  }
}

/**
 * Fetches the data from FormAction state and call the Invite Employee method
 */

export const inviteEmployeeMethod = (
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  metadataService: MetadataService,
) => {
  const formActionStateValue = store.selectSnapshot(FormActionState.getFormActionState);

  const profileStateValue = store.selectSnapshot(ProfileState.getProfile);

  const inviteEmployeeRequest = formActionStateValue.response?.formData;

  if (!inviteEmployeeRequest['email']) {
    alertHandlerService.showAlertFn('error', 'Email is a mandatory field.');
  } else {
    metadataService
      .inviteEmployee({
        email: inviteEmployeeRequest['email'],
        orgId: profileStateValue.activeOrganisationId as string,
        persona: 'employee', // static as of now, it has to be there in app grid
        roles: [],
      })
      .pipe(
        map((response) => {
          if (response['userId'] && response['userExist']) {
            redirectToPage(router, {
              textContent: '',
              zoneToNavigate: APP_ZONES.USER_MANAGEMENT,
              urlToNavigate: 'assign-success',
            } as BaseWidgetProperties);
            store.dispatch(new ResetFormDataAction());
          } else {
            redirectToPage(router, widgetProperties);
          }
        }),
      )
      .subscribe();
  }
};
