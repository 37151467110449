import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { ButtonActionType, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { ConfigService } from '../../../services/config-service/config-service.service';
import { FormSubmissionService } from '../../../services/form-submission-service/form-submission-service.service';
import { functionCtaType } from '../cta-button-actions.utils';
import { paymentApproverInitiateDomesticPayment } from '../domestic-payments/payment-approver-init-payment.utils';
import { checkPaymentRequest } from '../domestic-payments/payment-request';
import { paymentRequestApprove } from '../domestic-payments/payment-request-approve';
import { paymentRequestProcessing } from '../domestic-payments/payment-request-processing';
import { paymentRequestReject } from '../domestic-payments/payment-request-reject';

export const paymentRequestActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.PAYMENT_REQUEST,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) =>
      checkPaymentRequest(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(FormSubmissionService),
        injector.get(ConfigService),
      ),
  ],
  [
    ButtonActionType.PAYMENT_REQUEST_PROCESSING,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) =>
      paymentRequestProcessing(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
      ),
  ],
  [
    ButtonActionType.PAYMENT_REQUEST_APPROVE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) =>
      paymentRequestApprove(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
      ),
  ],
  [
    ButtonActionType.PAYMENT_REQUEST_REJECT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) =>
      paymentRequestReject(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
      ),
  ],
  [
    ButtonActionType.PAYMENT_APPROVER_MAKE_PAYMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) =>
      paymentApproverInitiateDomesticPayment(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
      ),
  ],
]);
