import { Injector } from '@angular/core';
import { ButtonActionType, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { CtaButtonSignalService } from '../../../services/cta-button-signal-service/cta-button-signal.service';
import { editBeneficiary } from '../beneficiary-zone/edit-beneficiary.utils';
import { setBeneficiaryDataAndRedirectToEditBeneficiaryPage } from '../beneficiary-zone/redirect-to-edit-beneficiary.utils';
import { callRemoveBeneficiaryAPI } from '../beneficiary-zone/remove-beneficiary.utils';
import { callEditIntlBeneficiaryAPI } from '../beneficiary-zone/submit-edit-beneficiary-request.utils';
import { functionCtaType } from '../cta-button-actions.utils';
import { addEditBeneficiary } from '../domestic-payments/add-beneficiary';
import { updateBeneficiaryDetail } from '../domestic-payments/edit-beneficiary';
import { skipAddressRedirection } from '../domestic-payments/payment-flow-redirection';
import { callIntlBeneficiaryAPI } from '../intl-payments/add-international-beneficiary';

export const beneficariesActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.ADD_INTERNATIONAL_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      injector.get(CtaButtonSignalService).setSignal({
        ['add_international_beneficiary']: false,
      });
      callIntlBeneficiaryAPI(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.REDIRECT_TO_EDIT_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      setBeneficiaryDataAndRedirectToEditBeneficiaryPage(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.SUBMIT_EDIT_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      callEditIntlBeneficiaryAPI(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.ADD_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      addEditBeneficiary(injector, attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.UPDATE_BENEFICIARY_DETAILS,
    (injector: Injector) => {
      updateBeneficiaryDetail(injector);
    },
  ],
  [
    ButtonActionType.REMOVE_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      callRemoveBeneficiaryAPI(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.EDIT_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      editBeneficiary(attri.widgetProperties, injector);
    },
  ],
  [
    ButtonActionType.SKIP_BENEFICIARY_ADDRESS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      skipAddressRedirection(attri.widgetProperties, injector);
    },
  ],
]);
