import { Router } from '@angular/router';
import { APP_ZONES } from '@finxone-platform/shared/sys-config-types';
import { ConfigService } from '../../../services/config-service/config-service.service';

export const showEPINUtil = (router: Router, configService: ConfigService) => {
  const targetUrl = configService.reRouteIfModal(APP_ZONES.CARD_MANAGEMENT_ZONE, 'show-pin');
  if (targetUrl) {
    router.navigate(['confirmation-code'], {
      queryParams: {
        claimType: 'getEpin',
        confirmationType: 'PIN',
        returnUrl: targetUrl,
      },
    });
  }
};
