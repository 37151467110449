import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { ButtonActionType, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { functionCtaType, redirectToPage } from '../cta-button-actions.utils';
import { checkSourceAccountAndRedirectToPayNewIntlBeneficiary } from '../intl-payments/check-source-account-and-redirect-intl-beneficiary';
import { submitInternationPayment } from '../intl-payments/submit-international-payment';

export const paymentsActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.PAY_NEW_INTL_BENEFICIARY,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      checkSourceAccountAndRedirectToPayNewIntlBeneficiary(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
  [
    ButtonActionType.INIT_INTL_PAYMENT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      submitInternationPayment(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
      );
    },
  ],
  [
    ButtonActionType.SEPA_TRANSFER_PAYMENT_FLOW,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      redirectToPage(injector.get(Router), attri.widgetProperties);
    },
  ],
]);
