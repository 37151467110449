import { TitleCasePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { HlmMenuBarModule } from '@spartan-ng/ui-menu-helm';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'finxone-menu',
  templateUrl: './finxone-menu.component.html',
  styleUrls: ['./finxone-menu.component.scss'],
  standalone: true,
  imports: [HlmMenuBarModule, TitleCasePipe],
})
export class FinxoneMenuComponent implements AfterViewInit {
  @ViewChild('contextMenu') contextMenu!: ElementRef;
  @Output() menuItemClickEvent = new EventEmitter<{ name: string; isEnabled: boolean }>();
  @Input() menuItemList: { name: string; isEnabled: boolean }[];
  @Input() contextMenuEvent: MouseEvent;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.setContextMenuPosition();
  }

  private setContextMenuPosition() {
    if (this.contextMenu && this.contextMenuEvent) {
      // Get the position of the click
      const { clientX, clientY } = this.contextMenuEvent;

      // Set the styles dynamically using Renderer2
      this.renderer.setStyle(this.contextMenu.nativeElement, 'top', `${clientY}px`);
      this.renderer.setStyle(this.contextMenu.nativeElement, 'left', `${clientX}px`);
    }
  }

  menuItemClick(menuItem: { name: string; isEnabled: boolean }) {
    this.menuItemClickEvent.emit(menuItem);
  }
}
