import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { ButtonActionType, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import { FormSubmissionService } from '../../../services/form-submission-service/form-submission-service.service';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { functionCtaType, redirectToPage } from '../cta-button-actions.utils';
import { getOrganisationDataFromStoreAndCallUpdateMethod } from '../kyb-zone/kyb-zone-action.utils';
import { submitProfileZoneUserProfileChanges } from '../profile-zone/edit-user-profile.utils';
import { toggleBiometricsAuthentication } from '../profile-zone/toggle-biometric-authentication.utils';

export const userProfileActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.SUBMIT_PROFILE_CHANGES_IN_PROFILE_ZONE,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      submitProfileZoneUserProfileChanges(
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(FormSubmissionService),
      );
    },
  ],
  [
    ButtonActionType.CLEAR_FORM_STATE_AND_REDIRECT_TO_EDIT_PROFILE,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      const store = injector.get(Store);
      const router = injector.get(Router);
      store.dispatch(new ResetFormDataAction());
      redirectToPage(router, attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.TOGGLE_BIOMETRIC_AUTHENTICATION,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      toggleBiometricsAuthentication(
        injector.get(Store),
        injector.get(Router),
        attri.widgetProperties,
        injector.get(AlertHandlerService),
      );
    },
  ],
  [
    ButtonActionType.UPDATE_ORGANISATION,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      getOrganisationDataFromStoreAndCallUpdateMethod(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Actions),
        injector.get(Router),
        injector.get(AlertHandlerService),
        injector.get(MetadataService),
      );
    },
  ],
]);
