import { Router } from '@angular/router';
import { FormSubmissionService } from '@app/finxone-web-frontend/app/lib/services/form-submission-service/form-submission-service.service';
import { PaymentsErrorCodes } from '@app/translations';
import { FormActionTypeEnum, SEPAPaymentRailTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { APP_ZONES, BaseWidgetProperties, SystemRole } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { first, map } from 'rxjs';
import { GetBeneficiaryResponse } from '../../../services/account-service/account.type';
import { ConfigService } from '../../../services/config-service/config-service.service';
import { AccountState } from '../../../state/account.state';
import { BeneficiaryState } from '../../../state/beneficiary.state';
import { FormActionState } from '../../../state/form-submision.state';
import { ProfileState } from '../../../state/user-profile.state';
import { findAndShowAlertFromCode } from '../../alert-code-utils/alert-code.utils';
import { paymentValidationsForSEPA } from '../../payments/payment-validation';
import { formatNameAsUrl } from '../../zone-url.utils';
import { redirectToPage } from '../cta-button-actions.utils';

export function checkPaymentRequest(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  action: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  formSubmissionService: FormSubmissionService,
  configService: ConfigService,
) {
  checkBalanceAndRedirect(store, router, alertHandlerService);

  let activeUser: SystemRole;
  let activeUserType = '';
  const profile = store.selectSnapshot(ProfileState.getProfile);
  if (formSubmissionService.currentPageIsValid()) {
    configService
      .getRoles()
      .pipe(
        first(),
        map((roles) => {
          Object.entries(roles).forEach(([roleKey, value]) => {
            if (roleKey === profile.activeRole) {
              activeUser = value;
            }
          });
          if (activeUser?.userType) {
            activeUserType = activeUser.userType;
          }
        }),
      )
      .subscribe();
    //get system config from form state
    const systemConfig = store.selectSnapshot(AccountState.getPaymentGatewayConfig);
    const activeOrg = profile.orgList?.find((org) => org.id === profile.activeOrganisationId);

    //if exists system config then redirect to payment approver/requestor flow
    if (systemConfig?.paymentRequest?.enabled && activeUserType === 'org') {
      const isPaymentInitiator = !!activeOrg?.roles.find((value) =>
        systemConfig?.paymentRequest?.paymentInitiatorRoles.includes(value),
      );
      const isPaymentRequestor = !!activeOrg?.roles.find((value) =>
        systemConfig?.paymentRequest?.paymentRequestorRoles.includes(value),
      );
      const isPaymentApprover = !!activeOrg?.roles.find((value) =>
        systemConfig?.paymentRequest?.paymentApproverRoles.includes(value),
      );
      //Only initiator role have permission to make payment directly
      if (isPaymentInitiator) {
        router.navigateByUrl(`zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/payment-summary-initiator`);
      } else if (isPaymentRequestor || isPaymentApprover) {
        router.navigateByUrl(`/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/payment-summary-requestor`);
      } else {
        alertHandlerService.showAlertFn(
          'error',
          "You don't have permission to make a payment or to request for a payment",
        );
      }
    } else {
      redirectToPage(router, widgetProperties);
    }
  } else {
    alertHandlerService.showAlertFn('error', ' Please ensure that all the details are provided and correct.');
  }
}

function checkBalanceAndRedirect(store: Store, router: Router, alertHandlerService: AlertHandlerService) {
  const formStateData = store.selectSnapshot(FormActionState.getFormActionState).response?.formData;
  const activeAccountId = store.selectSnapshot(AccountState.getCurrentAccountId);

  const accountsData = store.selectSnapshot(AccountState.getAccounts);

  if (formStateData?.['amount']) {
    if (!formStateData || !accountsData) {
      throw new Error('formData or accountsData is undefined or null');
    }

    const account = accountsData.accounts.find((account) => account.accountId === activeAccountId);
    if (!account) {
      throw new Error('Account with activeAccountId not found');
    }

    const amountPlusFees = Number(formStateData?.['totalAmount'] || formStateData?.['amount']);
    const accountBalance = Number(account.balance);
    if (isNaN(accountBalance) || isNaN(amountPlusFees)) {
      throw new Error('Account balance or payment amount NaN');
    }

    const isBalanceAvailable = accountBalance >= amountPlusFees;

    if (!isBalanceAvailable) {
      findAndShowAlertFromCode(store, router, alertHandlerService, [PaymentsErrorCodes.INSUFFICIENT_BALANCE]);
      throw new Error('Enter a lower amount to continue.');
    }

    /**
     * SEPA transaction validation only works with @default {EURO}
     */
    if (account?.currency === 'EUR') {
      return validateSEPATransaction(
        store,
        router,
        alertHandlerService,
        amountPlusFees,
        formStateData?.['bid'],
      );
    }
  } else {
    // Validate the transaction when amount is invalid.
    findAndShowAlertFromCode(store, router, alertHandlerService, [PaymentsErrorCodes.INVALID_AMOUNT]);
    throw new Error('Please enter a valid amount to proceed with the payment.');
  }
}

/**
 * Validate SEPA Transaction and handle validations for Target2 payment rail.
 * @param {Store} store - The application store instance.
 * @param {AlertHandlerService} alertHandlerService - Service to handle alert messages.
 * @param {number} amountPlusFees - Total amount including fees to validate.
 * @param {string} bid - Beneficiary ID for validation.
 * @throws Will throw an error if SEPA validations fail.
 */
const validateSEPATransaction = (
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  amountPlusFees: number,
  bid: string,
) => {
  // Get payment rail type from the store
  const paymentRail = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.SEPA_PAYMENT),
  )?.formData?.paymentRail as SEPAPaymentRailTypeEnum;

  const toggleRailSelector = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.SEPA_PAYMENT_RAIL_FLAG),
  )?.formData?.toggleRailSelector;

  // Fetch beneficiary details based on the provided bid
  const beneficiaryList = store.selectSnapshot(BeneficiaryState.getBeneficiary);
  const beneficiaryDetails = beneficiaryList?.items?.find((v) => v.id === bid);

  // Perform SEPA payment rail validations
  if (toggleRailSelector && paymentRail) {
    const paymentValidations = paymentValidationsForSEPA[paymentRail];

    // Validate minimum and maximum transaction limits
    if (amountPlusFees < 0) {
      findAndShowAlertFromCode(store, router, alertHandlerService, [PaymentsErrorCodes.AMOUNT_TOO_LOW]);
      throw new Error(`The entered amount is below the minimum allowed limit.`);
    } else if (amountPlusFees > Number(paymentValidations?.maximumTransactionLimit)) {
      findAndShowAlertFromCode(store, router, alertHandlerService, [
        PaymentsErrorCodes.EXCEEDED_TRANSFER_LIMIT,
      ]);
      throw new Error(`The transfer amount exceeds the allowed limit for ${paymentRail}.`);
    }

    // Validate additional requirements for the Target2 payment rail.
    return validateSEPATarget2PaymentRail(
      store,
      router,
      paymentRail,
      beneficiaryDetails,
      alertHandlerService,
    );
  }
};

/**
 * Validate additional requirements for the Target2 payment rail.
 * @param {SEPAPaymentRailTypeEnum} paymentRail - SEPA payment rail type.
 * @param {GetBeneficiaryResponse | undefined} beneficiaryDetails - Details of the beneficiary.
 * @param {AlertHandlerService} alertHandlerService - Service to handle alert messages.
 * @throws Will throw an error if Target2-specific validations fail.
 */
const validateSEPATarget2PaymentRail = (
  store: Store,
  router: Router,
  paymentRail: SEPAPaymentRailTypeEnum,
  beneficiaryDetails: GetBeneficiaryResponse | undefined,
  alertHandlerService: AlertHandlerService,
) => {
  if (paymentRail === SEPAPaymentRailTypeEnum.TARGET2) {
    // Validate BIC for Target2 payments
    if (!beneficiaryDetails?.destinationIdentifier?.bic) {
      findAndShowAlertFromCode(store, router, alertHandlerService, [
        PaymentsErrorCodes.PAYMENT_FAILED_BIC_REQUIRED,
      ]);
      throw new Error(
        'Mandatory field missing: BIC is required for Target2 payments. Please complete the required fields and try again.',
      );
    }

    // Validate address details for Target2 payments
    if (!beneficiaryDetails?.address || Object.keys(beneficiaryDetails?.address).length === 0) {
      findAndShowAlertFromCode(store, router, alertHandlerService, [
        PaymentsErrorCodes.PAYMENT_FAILED_ADDRESS_REQUIRED,
      ]);
      throw new Error(
        'Mandatory field missing: Address is required for Target2 payments. Please complete the required fields and try again.',
      );
    }
  }
};
