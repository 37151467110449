import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { ButtonActionType, UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { CardManagementService } from '../../../services/card-management-service/card-management-service';
import { ConfigService } from '../../../services/config-service/config-service.service';
import { WorkflowFormsService } from '../../../services/workflow-forms/workflow-forms.service';
import { onCancelCard, refreshCardsAndRedirect } from '../card-management-v2/cancel-card-actions.utils';
import { getSecureCardDetails } from '../card-management-v2/get-sensitive-details.utils';
import { shareCvvDetails } from '../card-management-v2/secure-cvv-details.utils';
import { onSetCardName } from '../card-management-v2/set-card-name.utils';
import { showEPINUtil } from '../card-management-v2/show-epin.utils';
import { functionCtaType } from '../cta-button-actions.utils';
import { updateBeneficiaryFormData } from '../domestic-payments/update-beneficiary-form-data';

export const cardManagementActions: Map<ButtonActionType, functionCtaType> = new Map([
  [
    ButtonActionType.USE_SUGGESTED_NAME_COP,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      updateBeneficiaryFormData(injector, attri.widgetProperties);
    },
  ],
  [
    ButtonActionType.SECURE_CVV_DETAILS,
    (injector: Injector) => {
      shareCvvDetails(injector.get(CardManagementService));
    },
  ],
  [
    ButtonActionType.CANCEL_CARD,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      onCancelCard(injector.get(Store), injector.get(AlertHandlerService), injector.get(Router));
    },
  ],
  [
    ButtonActionType.SET_CARD_NAME,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      onSetCardName(injector.get(Store), injector.get(Router), injector.get(WorkflowFormsService));
    },
  ],
  [
    ButtonActionType.SHOW_PIN,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      showEPINUtil(injector.get(Router), injector.get(ConfigService));
    },
  ],
  [
    ButtonActionType.REFRESH_CARDS_AND_REDIRECT,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      refreshCardsAndRedirect(injector, attri);
    },
  ],
  [
    ButtonActionType.GET_SENSITIVE_CARD_DETAILS,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      getSecureCardDetails(injector.get(Router));
    },
  ],
]);
