import { Router } from '@angular/router';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';

import { HttpErrorResponse } from '@angular/common/http';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { PaymentRequestPayload } from '@finxone-platform/shared/utils';
import { GeneratePaymentRequest } from '../../../actions/payment-request.action';
import { FormActionState } from '../../../state/form-submision.state';
import { NotificationState } from '../../../state/notifications.state';

export function paymentRequestReject(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  action: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
) {
  const formState = store.selectSnapshot(FormActionState.getFormActionState);

  if (formState?.response?.formData) {
    const paymentRequestSummary = store.selectSnapshot(
      NotificationState.getNotification,
    ).paymentRequestSummary;

    if (paymentRequestSummary) {
      const payload: PaymentRequestPayload = {
        ids: [paymentRequestSummary.recordId],
        approvedStatus: 'rejected',
      };
      store.dispatch(new GeneratePaymentRequest(payload)).subscribe({
        next: () => {
          router.navigateByUrl(`/zones/payments/payment-request-failed`, {
            replaceUrl: true,
          });
        },
        error: (err: HttpErrorResponse) => {
          router.navigateByUrl(`/zones/profile/notification-centre`, {
            replaceUrl: true,
          });
          console.error('Error generating payment request:', err);
        },
      });
    }
  } else {
    alertHandlerService.showAlertFn('error', 'FormState data not found.');
    throw Error('FormState data not found.');
  }
}
