import { Router } from '@angular/router';
import { ProfileErrorCodes } from '@app/translations';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { ClearFormDataActionWithId } from '../../../actions/form-submission.action';
import { FormActionState } from '../../../state/form-submision.state';
import { findAndShowAlertFromCode } from '../../alert-code-utils/alert-code.utils';
import { redirectToPage } from '../cta-button-actions.utils';

export function toggleBiometricsAuthentication(
  store: Store,
  router: Router,
  widgetProperties: BaseWidgetProperties,
  alertService: AlertHandlerService,
) {
  const flutterWebView = (window as any).flutter_inappwebview;
  const biometricsFormValue = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BIOMETRICS_AUTHENTICATION_TOGGLE),
  )?.formData;
  updateBiometricsFlagInStorage(biometricsFormValue.currentValue);
  flutterWebView?.callHandler('biometricsPreference', biometricsFormValue?.currentValue ?? false);
  const alertCode = biometricsFormValue.currentValue
    ? ProfileErrorCodes.BIOMETRIC_AUTH_ENABLED_SUCCESSFULLY
    : ProfileErrorCodes.BIOMETRIC_AUTH_DISABLED_SUCCESSFULLY;
  findAndShowAlertFromCode(store, router, alertService, [alertCode]);
  store.dispatch(new ClearFormDataActionWithId(FormActionTypeEnum.BIOMETRICS_AUTHENTICATION_TOGGLE));
  redirectToPage(router, widgetProperties);
}

export function updateBiometricsFlagInStorage(isChecked: boolean) {
  const appPreferences = localStorage.getItem('APP_PREFERENCES');
  if (appPreferences) {
    const parsedPreferences = JSON.parse(appPreferences);
    parsedPreferences.isBiometricEnabled = isChecked;
    localStorage.setItem('APP_PREFERENCES', JSON.stringify(parsedPreferences));
  } else {
    localStorage.setItem(
      'APP_PREFERENCES',
      JSON.stringify({
        isBiometricEnabled: isChecked,
      }),
    );
  }
}
