import { Injectable } from '@angular/core';
import { Locale } from '@finxone-platform/shared/enums';
import {
  FeeManagementServiceType,
  OnboardingConfigFlowTypes,
  UISettings,
} from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetProjectSettings, UpdateProjectSettings } from '../actions/project-settings.action';

export interface ProjectSettingsStateModel {
  onboardingFlow: OnboardingConfigFlowTypes;
  defaultCurrency: string;
  checkPayeeEnabled: boolean;
  smsAuthenticationEnabled: boolean;
  smsAuthenticationExclusion: { [key: string]: string[] };
  enableBeneficiaryFirstAndLastName: boolean;
  locale: string;
  feeManagement: FeeManagementServiceType;
  defaultOtherAlerts: boolean;
  uiSettings?: UISettings;
}

@State<ProjectSettingsStateModel>({
  name: 'projectSettings',
  defaults: {
    onboardingFlow: '' as OnboardingConfigFlowTypes,
    defaultCurrency: 'GBP',
    checkPayeeEnabled: true,
    smsAuthenticationEnabled: true,
    smsAuthenticationExclusion: {},
    locale: Locale.en_GB,
    enableBeneficiaryFirstAndLastName: false,
    feeManagement: {
      enabled: true,
      retryFailedFeeEnabled: true,
      feeReversalEnabled: true,
    },
    defaultOtherAlerts: true,
  },
})
@Injectable()
export class ProjectSettingsState {
  @Selector()
  static getProjectSettings(state: ProjectSettingsStateModel) {
    return state;
  }

  @Action(UpdateProjectSettings)
  updateProjectSettings(ctx: StateContext<ProjectSettingsStateModel>, action: UpdateProjectSettings) {
    ctx.patchState(action.payload);
  }

  @Action(SetProjectSettings)
  setProjectSettings(ctx: StateContext<ProjectSettingsStateModel>, action: SetProjectSettings) {
    ctx.patchState({
      ...ctx.getState(),
      ...action.payload,
    });
  }
}
