<div class="finxone-menu-content fixed z-[1] rounded-[6px] border-0" #contextMenu>
  <hlm-menu variant="workflowMenu">
    <hlm-menu-group>
      @for (item of menuItemList; track $index) {
      <button
        hlmMenuItem
        (click)="menuItemClick(item)"
        class="pt-2 pb-2 border-0 cursor-[_pointer] menu-button"
      >
        <span> {{ item?.['name'] | titlecase }} </span>
      </button>
      }
    </hlm-menu-group>
  </hlm-menu>
</div>
