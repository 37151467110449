import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AddEmployeeRequestType,
  AlertDetails,
  AssignPersona,
  AssociateUserPersonaResponse,
  GenerateOtpPayload,
  ImportUsers,
  ImportUsersPayload,
  InviteEmployeeType,
  KycCheckStatus,
  KycLevel,
  KycTokenResponse,
  NotificationSettings,
  NotificationSettingsPayload,
  OnboardUserResponse,
  OrganisationOnboardingRequest,
  OrganisationVerificationRequest,
  ResponseWithAlertCodesType,
  Roles,
  UserInfoType,
  UserPayload,
  UserProfileData,
  UsersList,
  VerificationFlag,
  VerificationOrganisationData,
  ViewUserAssignPersona,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import jwt_decode, { InvalidTokenError } from 'jwt-decode';
import { Observable, Subject, catchError, from, map, of, switchMap } from 'rxjs';
import { RemoveProgressBarStack } from '../../actions/progress-bar.action';
import { AutoSuggestAddressResponseItems, FindAddressResponse } from '../../dtos/address.dto';
import { MotVerificationResponseItem } from '../../dtos/mot.dto';
import { PrivacyPolicyType } from '../../dtos/privacy-policy.dto';
import { ConfigService } from '../config-service/config-service.service';
import { CheckCompanyResponse, OnboardIndividualRequest } from './metadata-service.type';
import { generateRequestHeaders } from './otp-utils';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  private baseUrl = '';

  verificationFlag: Subject<VerificationFlag> = new Subject();
  // import user observable
  importselecteduser: Subject<ImportUsers> = new Subject();

  constructor(private http: HttpClient, private configService: ConfigService, private store: Store) {
    this.configService.getApi('metadata_service').subscribe((api) => {
      this.baseUrl = api;
    });
  }

  verifyOtp(mobileNumber: string, verificationCode: string): Observable<boolean> {
    return this.http
      .post(
        this.baseUrl + '/users/verify-otp',
        {
          mobileNumber: mobileNumber,
          verificationCode: verificationCode,
        },
        { responseType: 'text' },
      )
      .pipe(
        map((v) => {
          return true;
        }),
        catchError<boolean, Observable<boolean>>((_err, _caught) => {
          console.error(`Error verifying otp: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  autosuggestAddress(address: string): Observable<AutoSuggestAddressResponseItems> {
    return this.http.get<AutoSuggestAddressResponseItems>(`${this.baseUrl}/address/autosuggest-address`, {
      params: { term: address },
    });
  }

  getAddressById(id: string): Observable<FindAddressResponse<[]>> {
    return this.http.get<FindAddressResponse<[]>>(`${this.baseUrl}/address/find-address`, {
      params: { id: id },
    });
  }

  verifyMot(registeredNumber: string): Observable<MotVerificationResponseItem> {
    return this.http.get<MotVerificationResponseItem>(this.baseUrl + '/mot/verification', {
      params: { 'registed-number': registeredNumber },
    });
  }

  onboardIndividual(
    form: OnboardIndividualRequest,
  ): Observable<ResponseWithAlertCodesType<OnboardUserResponse>> {
    return this.http
      .post<ResponseWithAlertCodesType<OnboardUserResponse>>(
        `${this.baseUrl}/users/onboarding-individual`,
        form,
      )
      .pipe(
        map((response: ResponseWithAlertCodesType<OnboardUserResponse>) => {
          return response;
        }),
        catchError((_err) => {
          console.error(`Error onboarding individual: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  organisationOnboarding(form: OrganisationOnboardingRequest): Observable<string> {
    return this.http.post<{ id: string }>(`${this.baseUrl}/organisations`, form).pipe(
      map((response) => {
        return response.id;
      }),
      catchError<string, Observable<string>>((_err) => {
        console.error(`Error organisationOnboarding: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  associateUserOrganisation(orgId: string): Observable<boolean> {
    return this.http.put(`${this.baseUrl}/organisations/${orgId}/associate-user`, {}).pipe(
      map(() => {
        this.store.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'associateUserOrganisation',
          }),
        );
        return true;
      }),
      catchError<boolean, Observable<boolean>>((_err) => {
        this.store.dispatch(
          new RemoveProgressBarStack({
            uniqueId: 'associateUserOrganisation',
          }),
        );
        console.error(`Error associateUserOrganisation: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  getPrivacyContent(privacyType: string): Observable<PrivacyPolicyType> {
    return this.http.get<PrivacyPolicyType>(`${this.baseUrl}/cmspages/content?slug=${privacyType}`);
  }

  checkEmail(email: string): Observable<boolean> {
    return this.http
      .post(
        this.baseUrl + '/users/check-email-exist',
        {
          email: email,
        },
        { responseType: 'text' },
      )
      .pipe(
        map((v) => {
          return true;
        }),
        catchError<boolean, Observable<boolean>>((_err, _caught) => {
          if (_err.status == 409 || _err.status == 400) {
            return of(false);
          } else {
            console.error(`Error while check email exist: ${JSON.stringify(_err)}`);
            throw _err;
          }
        }),
      );
  }

  checkCompanyNumber(company_number: string, company_name: string): Observable<CheckCompanyResponse> {
    return this.http
      .post<CheckCompanyResponse>(
        this.baseUrl + '/company/check-number',
        {
          company_number: company_number,
          company_name: company_name,
        },
        { responseType: 'json' },
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        catchError<any, Observable<boolean>>((_err, _caught) => {
          console.error(`Error fetching Check company number: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  updateProfile(form: UserProfileData): Observable<boolean> {
    return this.http
      .post(`${this.baseUrl}/users/update-user-profile`, form, {
        responseType: 'text',
      })
      .pipe(
        map(() => {
          return true;
        }),
        catchError<boolean, Observable<boolean>>((_err, _caught) => {
          console.error(`Error onboarding individual: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  updateAttributes(attributes: object): Observable<boolean> {
    return this.http.patch(`${this.baseUrl}/users/attributes`, attributes).pipe(
      map(() => {
        return true;
      }),
      catchError<boolean, Observable<boolean>>((_err, _caught) => {
        console.error(`Error updating attributes: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  // http://localhost:8002/user?page=1&size=10&sort=DESC&projectId=5471e302-216e-4ba9-b554-7dd3ee895516&sortKey=lastName
  getUsers(
    page: number,
    size: number,
    sortKey: string,
    sortOrder: string,
    search: string,
  ): Observable<UsersList> {
    return this.http.get<UsersList>(
      `${this.baseUrl}/users?page=${page}&size=${size}&sortKey=${sortKey}&sortOrder=${sortOrder}&query=${search}`,
    );
  }

  addUser(form: UserPayload): Observable<boolean> {
    return this.http.post(`${this.baseUrl}/users`, form).pipe(
      map(() => {
        return true;
      }),
      catchError<boolean, Observable<boolean>>((_err, _caught) => {
        console.error(`Error onboarding individual: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  getRoles(): Observable<Roles[]> {
    return this.http.get<Roles[]>(`${this.baseUrl}/roles`);
  }

  getImportUsers(form: ImportUsersPayload): Observable<ImportUsers[]> {
    return this.http.post<ImportUsers[]>(`${this.baseUrl}/importusers/autosuggest`, form);
  }
  getUser(id?: string): Observable<UserInfoType> {
    if (id) {
      return this.http.get<UserInfoType>(`${this.baseUrl}/users/${id}`);
    } else {
      const token = localStorage.getItem('token') as string;
      if (token) {
        try {
          const jwt: any = jwt_decode(token);
          const userId = jwt.sub;
          return this.http.get<UserInfoType>(`${this.baseUrl}/users/${userId}`);
        } catch (error) {
          if (error instanceof InvalidTokenError) {
            console.error('Token decode issue when fetching user data');
          } else console.error('getUser', error);
        }
      }
    }
    return of();
  }

  updateUser(form: UserPayload, addProfileStatus = false): Observable<boolean> {
    form.profileStatus = addProfileStatus;
    return this.http
      .post(`${this.baseUrl}/users/update-details`, form, {
        responseType: 'text',
      })
      .pipe(
        map(() => {
          return true;
        }),
        catchError<boolean, Observable<boolean>>((_err, _caught) => {
          console.error(`Error onboarding individual: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  getKycAccessToken(type: KycLevel): Observable<KycTokenResponse> {
    return this.http.post<KycTokenResponse>(`${this.baseUrl}/verification-provider/get-token`, {
      type: type,
    });
  }

  setApplicantData(id: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/verification-provider/set-applicant?id=${id}`, {});
  }
  setCompanyInfo(id: string): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.baseUrl}/verification-provider/update-applicant-company?id=${id}`,
      {},
    );
  }

  checkApplicantStatus(type: KycLevel): Observable<KycCheckStatus> {
    return this.http.get<KycCheckStatus>(`${this.baseUrl}/verification-provider/check-status?level=${type}`);
  }

  generateOtpPhoneEmail(payload: GenerateOtpPayload): Observable<boolean> {
    return this.http.get(this.baseUrl + '/users/user-info', { responseType: 'text' }).pipe(
      // First switchMap to flatten the outer Observable
      switchMap((secret) => {
        return from(generateRequestHeaders(secret)).pipe(
          // Second switchMap to flatten the inner Observable
          switchMap((headers) => {
            return this.http
              .post(this.baseUrl + '/users/generate-otp-common', payload, {
                headers: {
                  ...headers,
                },
                responseType: 'text',
              })
              .pipe(
                map(() => true),
                catchError((error) => {
                  console.error(`Error generating otp:`, error);
                  throw error;
                }),
              );
          }),
        );
      }),
    );
  }

  verifyOtpPhoneEmail(payload: GenerateOtpPayload): Observable<boolean> {
    return this.http
      .post(this.baseUrl + '/users/verify-otp-common', payload, {
        responseType: 'text',
      })
      .pipe(
        map((v) => {
          return true;
        }),
        catchError<boolean, Observable<boolean>>((_err, _caught) => {
          console.error(`Error verifying otp: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  verifyEmailToken(token: string): Observable<boolean> {
    return this.http.get(this.baseUrl + `/users/verify-email-token/${token}`).pipe(
      map((v) => {
        return true;
      }),
      catchError<boolean, Observable<boolean>>((_err, _caught) => {
        console.error(`Error verifying token: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  getLostCardFromValue() {
    return {
      fullname: '',
      country_code: '',
      mobile: '',
      flat_name: '',
      street_address: '',
      city: '',
      country: '',
      postcode: '',
      property_name: '',
      property_number: '',
      reason: '',
    };
  }

  updateNotificationSettings(
    payload: Partial<NotificationSettingsPayload>,
  ): Observable<NotificationSettings> {
    return this.http.post<NotificationSettings>(this.baseUrl + '/users/settings', payload).pipe(
      map((response) => {
        return response;
      }),
      catchError((_err) => {
        console.error(`Error updating notification settings: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  getOrganisation(orgId: string): Observable<VerificationOrganisationData> {
    return this.http.get<VerificationOrganisationData>(`${this.baseUrl}/organisations/${orgId}`);
  }

  updateOrganisation(orgId: string, data: any): Observable<OrganisationVerificationRequest> {
    const payload = {
      name: data?.organisation_companyName,
      country: data?.addressCountry,
      address1: '',
      address2: data?.addressStreet,
      city: data?.addressCity,
      state: data?.addressState,
      postalCode: data?.addressPostCode,
      extraAttributes: {},
      identificationNumber: data?.identificationNumber,
      incorporationDate: data?.incorporationDate,
    };
    return this.http.patch<OrganisationVerificationRequest>(
      `${this.baseUrl}/organisations/${orgId}`,
      payload,
    );
  }

  getUsersList(id: string, page: number, size: number): Observable<UsersList> {
    return this.http.get<UsersList>(`${this.baseUrl}/organisations/${id}/members?page=${page}&size=${size}`);
  }

  getUsersListWithPersona(id: string, page: number, size: number, searchText: string): Observable<UsersList> {
    return this.http.get<UsersList>(
      `${this.baseUrl}/organisations/${id}/members?page=${page}&size=${size}&metadataInclude=persona&query=${searchText}`,
    );
  }
  addEmployee(payload: AddEmployeeRequestType) {
    return this.http.post<{ userId: string }>(this.baseUrl + '/users', payload).pipe(
      map((response) => response),
      catchError((_err) => {
        console.error(`Error while adding an employee: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  inviteEmployee(payload: InviteEmployeeType) {
    return this.http
      .put<{ userId: string; userExist: boolean }>(
        this.baseUrl + `/organisations/${payload.orgId}/associate-user-persona`,
        payload,
      )
      .pipe(
        map((response) => response),
        catchError((_err) => {
          console.error(`Error while inviting an employee: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }
  associateUserPersona(orgId: string, payload: AssignPersona): Observable<AssociateUserPersonaResponse> {
    return this.http
      .put<AssociateUserPersonaResponse>(
        `${this.baseUrl}/organisations/${orgId}/associate-user-persona`,
        payload,
      )
      .pipe(
        catchError((_err) => {
          console.error(`Error while creating Persona: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  removeUser(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}/users/remove-org-user/${id}`);
  }

  viewUserAssignPersona(payload: ViewUserAssignPersona): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}/users/update-roles`, payload).pipe(
      catchError((_err) => {
        console.error(`Error while updating Persona: ${JSON.stringify(_err)}`);
        throw _err;
      }),
    );
  }

  getTravelUsersList(userType: string, id: string, page: number, size: number): Observable<UsersList> {
    return this.http.get<UsersList>(
      `${this.baseUrl}/travel-users/${userType}?type=events&typeId=${id}&page=${page}&size=${size}`,
    );
  }

  public getSystemAlerts(): Observable<{
    [errorCode: string]: AlertDetails;
  }> {
    return this.configService.getSystemAlerts();
  }
}
