import { Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormActionTypeEnum, OtpParam } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  ACCOUNTTYPE,
  APP_ZONES,
  BaseWidgetProperties,
  CheckPayeeMatchStatus,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { catchError, map, take } from 'rxjs';
import { ClearBulkPaymentList, GetBulkPaymentList } from '../../../actions/bulk-payment.action';
import { SetFormActionWithId } from '../../../actions/form-submission.action';
import { AccountService } from '../../../services/account-service/account-service.service';
import { AddBeneficiaryRequest } from '../../../services/account-service/account.type';
import { BackOverrideService } from '../../../services/back-override-service/back-override.service';
import { CopResponseState } from '../../../state/cop-response.state';
import { FormActionState } from '../../../state/form-submision.state';
import { formatNameAsUrl } from '../../zone-url.utils';
import { addEditBeneficiary } from './add-beneficiary';

export function updateBeneficiaryFormData(injector: Injector, widgetProperties: BaseWidgetProperties) {
  const store = injector.get(Store);
  const router = injector.get(Router);
  const accountService = injector.get(AccountService);
  const route = injector.get(ActivatedRoute);
  const alertHandlerService = injector.get(AlertHandlerService);
  const backService = injector.get(BackOverrideService);
  //get correct bank name from state
  const copState = store.selectSnapshot(CopResponseState.getCopResponseState);
  const suggestedName = copState.name;

  //decide which form state to update
  route.queryParams.pipe(take(1)).subscribe((params) => {
    const beneficiaryFlowParam = params['flow'];
    if (beneficiaryFlowParam === 'bulk-payment') {
      callContinueWithSuggestionAPI(store, accountService, alertHandlerService, router, backService);
      return;
    }
    let formData;
    let formActionId;
    if (beneficiaryFlowParam === OtpParam.AddBeneficiary) {
      formData = store.selectSnapshot(
        FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_BENEFICIARY),
      )?.formData as AddBeneficiaryRequest;
      formActionId = FormActionTypeEnum.ADD_BENEFICIARY;
      formData.name = suggestedName;
    } else {
      formData = store.selectSnapshot(
        FormActionState.getFormActionStateWithId(FormActionTypeEnum.EDIT_BENEFICIARY),
      )?.formData;
      formActionId = FormActionTypeEnum.EDIT_BENEFICIARY;
      formData.payload.name = suggestedName;
    }
    //in the scenario where account type is wrong, swap account type as well
    if (
      copState.matchStatusCode === CheckPayeeMatchStatus.ACCOUNT_TYPE_MISMATCH_NAME_CLOSE_MATCH ||
      copState.matchStatusCode === CheckPayeeMatchStatus.NAME_MATCHED
    ) {
      formData.accountType =
        formData.accountType === ACCOUNTTYPE.PERSONAL ? ACCOUNTTYPE.BUSINESS : ACCOUNTTYPE.PERSONAL;
    }
    store.dispatch(new SetFormActionWithId({ type: '', formData }, formActionId));
    //continue to add/edit the beneficiary
    addEditBeneficiary(injector, widgetProperties);
  });
}

const callContinueWithSuggestionAPI = (
  store: Store,
  accountService: AccountService,
  alertHandlerService: AlertHandlerService,
  router: Router,
  backService: BackOverrideService,
) => {
  const formActionData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.BULK_PAYMENTS),
  );
  const masterFileId = formActionData?.formData.masterFileId;
  const transactionId = formActionData?.formData.transactionId;

  // passing suggestion: true as we call the continue with suggestion function here
  accountService
    .bulkPaymentContinueAnywayAndWithSuggestion(masterFileId, transactionId, { suggestion: true })
    .pipe(
      take(1),
      map(() => {
        store.dispatch(new ClearBulkPaymentList());
        store.dispatch(new GetBulkPaymentList(1, 10, masterFileId));
        const nextRoute = `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/bulk-payment-beneficiary-review`;
        router.navigateByUrl(nextRoute);
        backService.setBackOverride('/zones/landing/home', nextRoute, FormActionTypeEnum.ADD_BENEFICIARY);
        alertHandlerService.showAlertFn('success', 'Updated Successfully.');
      }),
      catchError((error) => {
        return error;
      }),
    )
    .subscribe();
};
