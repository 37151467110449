import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { createLoggedInModalPageUrl } from '@app/finxone-web-frontend/app/Modules/zones/modal-utils';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  BaseWidgetProperties,
  ButtonActionType,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { AccountService } from '../../services/account-service/account-service.service';
import { ConfigService } from '../../services/config-service/config-service.service';
import { FormSubmissionService } from '../../services/form-submission-service/form-submission-service.service';
import { KeycloakWrapperService } from '../../services/keycloak-wrapper-service/keycloak-wrapper.service';
import { MetadataService } from '../../services/metadata-service/metadata-service.service';
import { formatNameAsUrl } from '../zone-url.utils';
import { beneficariesActions } from './cta-actions/beneficiaries-actions';
import { bulkPaymentsActions } from './cta-actions/bulk-payments-actions';
import { cardManagementActions } from './cta-actions/card-management-actions';
import { feeManagementActions } from './cta-actions/fee-management-actions';
import { kycVerificationActions } from './cta-actions/kyc-verification-actions';
import { paymentRequestActions } from './cta-actions/payment-request-actions';
import { paymentsActions } from './cta-actions/payments-actions';
import { revenirActions } from './cta-actions/revenir-actions';
import { userManagementActions } from './cta-actions/user-management-actions';
import { userProfileActions } from './cta-actions/user-profile-actions';
import { handleBusinessUserRedirectionOnOnboardingFlows } from './onboarding-zone/business-user-onboarding-redirection-action.utils';

export type functionCtaType = (injector: Injector, attri: UiZoneWidgetAttributeConfig) => void;
export type CtaActionGroup = {
  [key: string]: functionCtaType;
};

export const ctaButtonFunctionsMap: Map<ButtonActionType, functionCtaType> = new Map<
  ButtonActionType,
  functionCtaType
>([
  ...Array.from(paymentRequestActions.entries()),
  ...Array.from(feeManagementActions.entries()),
  ...Array.from(bulkPaymentsActions.entries()),
  ...Array.from(beneficariesActions.entries()),
  ...Array.from(kycVerificationActions.entries()),
  ...Array.from(revenirActions.entries()),
  ...Array.from(cardManagementActions.entries()),
  ...Array.from(userManagementActions.entries()),
  ...Array.from(paymentsActions.entries()),
  ...Array.from(userProfileActions.entries()),
  [
    ButtonActionType.REDIRECT_BUSINESS_USER_TO_LOGIN,
    (injector: Injector, attri: UiZoneWidgetAttributeConfig) => {
      handleBusinessUserRedirectionOnOnboardingFlows(
        attri.widgetProperties,
        injector.get(Store),
        injector.get(Router),
        injector.get(KeycloakWrapperService),
        injector.get(ConfigService),
      );
    },
  ],
]);

export function redirectToPage(router: Router, widgetProperties: BaseWidgetProperties, injector?: Injector) {
  const configService = injector?.get(ConfigService);
  if (configService) {
    const url = configService.reRouteIfModal(
      widgetProperties['zoneToNavigate'],
      widgetProperties['urlToNavigate'],
    );
    router.navigateByUrl(url);
  } else {
    router.navigateByUrl(
      `/zones/${formatNameAsUrl(widgetProperties['zoneToNavigate'])}/${widgetProperties['urlToNavigate']}`,
    );
  }
}

export function redirectToModalPage(router: Router, widgetProperties: BaseWidgetProperties) {
  try {
    const navigationUrl = createLoggedInModalPageUrl({
      zoneUrl: formatNameAsUrl(widgetProperties['zoneToNavigate']),
      pageUrl: widgetProperties['urlToNavigate'],
    });
    router.navigateByUrl(navigationUrl);
  } catch (error) {
    console.error('redirectToModalPage:', error);
  }
}
